import Vue from 'vue';

Vue.use({
  install(Vue) {
    if (process.env.NODE_ENV === 'production') {
      window.fbq('init', '301045650573393');
    }

    Vue.prototype.$fbTrack = function (event, parameters = {}) {
      if (process.env.NODE_ENV === 'production') {
        window.fbq('track', event, parameters);
      }
    }
  }
});

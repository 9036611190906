import { login, signup, check, redirect, send, verify, startReset, verifyReset, updatePassword } from '@/services/authentication';
import { authorize, approve, reject } from '@/services/oauth';
import { saveTokenToCookie, clearTokenCookie } from '@/services/cookies';

import User from '@/models/user';

export default {
  state: () => ({
    expiration: null,
    token: null,
    user: null
  }),

  mutations: {
    setToken(state, token) {
      state.token = token;
      saveTokenToCookie(token.jwt, token.expiration);
    },
    setUser(state, token) {
      state.user = new User(token.data);
    },
    clear(state) {
      state.expiration = null;
      state.token = null;
      state.user = null;
      clearTokenCookie();
    }
  },

  actions: {
    async login({ dispatch }, credentials) {
      // console.log('login', credentials);
      const token = await login(credentials.username, credentials.password, credentials.redirectUrl);
      dispatch('loginFromToken', token);
    },

    async signup({ dispatch }, form) {
      const token = await signup(form);
      dispatch('loginFromToken', token);
    },

    async verify({ dispatch, state }, code) {
      // console.log('verify', code);
      const token = await verify(state.token.jwt, code);
      dispatch('loginFromToken', token);
    },

    loginFromToken({ commit, dispatch }, token) {
      // console.log('loginFromToken', token)
      try {
        commit('setToken', token);
        commit('setUser', token);
      } catch (error) {
        // log the user out if there is anything wrong with their token
        dispatch('logout');
      }
    },

    async check({ dispatch, state }) {
      let token = state.token?.jwt;
      if(!token)
        dispatch('logout');

      check(state.token?.jwt).catch(() => {
        // console.log('check', false);
        dispatch('logout');
      });
    },

    logout({ commit }) {
      // console.log('logout');
      commit('clear');
      window.location.replace('/');
    },

    send({ state }, type) {
      // console.log('send', type);
      return send(state.token.jwt, type);
    },

    resend({ state }, type) {
      // console.log('resend', type);
      return send(state.token.jwt, type);
    },

    async redirect({ state }) {
      return redirect(state.token.jwt);
    },

    startReset(store, { username, email }) {
      // console.log('startReset', username, email);
      return startReset(username, email);
    },

    verifyReset(store, { username, email, code }) {
      // console.log('verifyReset', username, email, code);
      return verifyReset(username, email, code);
    },

    updatePassword(store, { key, password }) {
      // console.log('updatePassword', key, password);
      return updatePassword(key, password);
    },

    authorizeApplication({ state }, params) {
      return authorize(state.token.jwt, params);
    },

    approveApplication({ state }, params) {
      return approve(state.token.jwt, params);
    },

    rejectApplication({ state }, params) {
      return reject(state.token.jwt, params);
    }
  },

  getters: {
    isAuthenticated(state) {
      return state.token !== null;
    },
    isVerified(state) {
      return state.user?.verified || false;
    },
    user(state) {
      if (!state.user) {
        throw new Error('User not found.')
      }
      return state.user;
    }
  }
}

<!--eslint-disable-->
<template>
  <div class="pt-7 mt-7 border-t border-brand-gray">
    <div v-if="hasFuturesLink" class="font-poppins text-lg text-brand-gray font-semibold mb-7">
      Want to login to your Futures Account?
      <a href="https://tradier.com/individuals/futures/login" class="text-brand-secondary font-normal underline hover:no-underline" tabindex="10">Login Here</a>
    </div>
    <div v-if="isSignupPage" class="text-xs text-brand-gray font-sans mt-0 mb-3">
      <p>&copy; {{ year }}, Tradier Inc., and subsidiaries. All rights reserved.</p>
      <slot />
      <p class="inline-block mt-3">
        <strong>Check the background of this investment professional on <a target="_blank" rel="noopener noreferrer" href="https://brokercheck.finra.org/firm/104982" class="text-brand-secondary hover:underline">FINRA's BrokerCheck »</a></strong>.
        <strong>Check the background of Tradier Futures by visiting <a target="_blank" rel="noopener noreferrer" href="https://www.nfa.futures.org/basicnet/" class="text-brand-secondary hover:underline">NFA's BASIC System »</a></strong>
      </p>
      <p class="inline-block mt-3"><strong>Options carry a high level of risk and are not suitable for all investors. Please read the <a target="_blank" rel="noopener noreferrer" href="https://api.tradier.com/v2/applications/agreements?key=options_disclosure" class="text-brand-secondary hover:underline">Characteristics and Risks of Standardized Options</a> before trading in options. Risk of loss in trading can be substantial and you should carefully consider the risks associated with trading options and using leverage as it relates to your investment objectives, time horizon and expenses.</strong></p>
      <p class="inline-block mt-3">Securities products are offered through Tradier Brokerage Inc. Member <a target="_blank" rel="noopener noreferrer" href="https://www.finra.org/" class="text-brand-secondary hover:underline">FINRA</a>/<a target="_blank" rel="noopener noreferrer" href="https://www.sipc.org/" class="text-brand-secondary hover:underline">SIPC</a> - Not FDIC Insured - No Bank Guarantee - May Lose Value. Commodity interest products, including futures and forex are offered by Tradier Futures (DBA of Lazzara Consulting Inc). Member <a target="_blank" rel="noopener noreferrer" href="https://www.nfa.futures.org/" class="text-brand-secondary hover:underline">NFA</a>/<a target="_blank" rel="noopener noreferrer" href="https://www.cftc.gov/" class="text-brand-secondary hover:underline">CFTC</a>. Tradier Futures does not maintain a research department as defined in CFTC Rule 1.71. <a target="_blank" rel="noopener noreferrer" href="https://tradier.com/legal/tradier-futures-risk-disclosure" class="text-brand-secondary hover:underline">Tradier Futures Risk Disclosure</a>.</p>
      <p class="inline-block mt-3">Tradier and its subsidiaries are not affiliated with and do not endorse any third-party platforms, methodologies, newsletters or similar services which are not solely produced by Tradier. Systems response and account access times may vary due to a variety of factors, including trading volumes, market conditions and system performance. Past performance is no guarantee of future returns. Use of these systems is at your own risk. Please visit <a target="_blank" rel="noopener noreferrer" href="https://tradier.com/legal" class="text-brand-secondary hover:underline">Tradier Disclosures/Policies</a> for a full list of policies and disclosures.</p>
      <p class="inline-block mt-3"><sup class="right-0">*</sup> Tradier Brokerage Inc. charges for exchange, clearing and regulatory fees. Other fees and applicable minimums may apply, see the Tradier Brokerage <a target="_blank" rel="noopener noreferrer" href="https://api.tradier.com/v2/applications/agreements?key=fee_schedule" class="text-brand-secondary hover:underline">Fee Schedule</a> for more details. Promotions are subject to change at any time. Tradier Brokerage, Inc. does not guarantee its accuracy, completeness, or suitability. Accounts are carried and cleared with Apex Clearing Inc. Version: {{ version }} ({{ environment }})</p>
    </div>
    <p v-else class="text-xs text-brand-gray font-sans mt-0">©{{ year }}, Tradier Inc., and subsidiaries. All rights reserved. Securities products are offered through Tradier Brokerage Inc. Member <a href="https://www.finra.org/" target="_blank" rel="noopener noreferrer" class="text-brand-secondary hover:underline">FINRA</a>/<a href="https://www.sipc.org/" target="_blank" rel="noopener noreferrer" class="text-brand-secondary hover:underline">SIPC</a> - Not FDIC Insured - No Bank Guarantee - May Lose Value. Commodity interest products, including futures and forex are offered by Tradier Futures (DBA of Lazzara Consulting Inc). Member <a href="https://www.nfa.futures.org/" target="_blank" rel="noopener noreferrer" class="text-brand-secondary hover:underline">NFA</a>/<a href="https://www.cftc.gov/" target="_blank" rel="noopener noreferrer" class="text-brand-secondary hover:underline">CFTC</a>. Please visit <a href="https://tradier.com/legal" target="_blank" class="text-brand-secondary hover:underline">Tradier Disclosures/Policies</a> for a full list of policies and disclosures. Version: {{ version }} ({{ environment }})</p>
  </div>
</template>

<script>
import pkg from '../../package';

export default {
  name: 'AppDisclaimer',
  props: {
    hasFuturesLink: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      version: pkg.version,
      environment: process.env.NODE_ENV,
      year: new Date().getFullYear()
    }
  },
  computed: {
    isSignupPage() {
      return this.$route.name === 'signup';
    }
  }
}
</script>

import Vue from 'vue'
import Application from './application.vue'
import router from './router'
import store from './store'
import plugins from './plugins'
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false

import './styles/style.scss'

// Sentry
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    debug: false,
    dsn: 'https://3c6c5c328c474127b6cd6de5bdc2a944@o1900.ingest.sentry.io/6055222',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['s-be-auth.tradier.com', 'p-be-auth.tradier.com'],
      }),
    ],
    tracesSampleRate: 0
  });
}

// == Gtag.js =================================
if (process.env.VUE_APP_GA4_MEASUREMENT_ID) {
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GA4_MEASUREMENT_ID,
      params: process.env.VUE_APP_COOKIE_ENV === 'staging' ? { 'debug_mode': true } : {}
    }
  });
}

const app = new Vue({
  router,
  store,
  plugins,
  render: h => h(Application)
}).$mount('#app')

if(process.env.NODE_ENV !== 'production')
  app.$mixpanel.disable()

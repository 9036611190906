import Cookies from 'js-cookie'
import { pick } from '@/utilities/helpers';

export const TOKEN_KEY = 'tradier_auth_token.local';
export const TOKEN_EXPIRATION = 'tradier_auth_token_expiration.local';
export const ONBOARDING_PARAMS_TOKEN_KEY = 'tradier_onboarding_params';

export const COOKIE_OPTIONS = {
  domain: (process.env.VUE_APP_COOKIE_ENV === 'production' || process.env.VUE_APP_COOKIE_ENV === 'staging') ? '.tradier.com' : 'localhost',
  sameSite: true,
  secure: true
}

export const ONBOARDING_PARAMS_COOKIE_OPTIONS = {
  path: '/',
  domain: (process.env.VUE_APP_COOKIE_ENV === 'production' || process.env.VUE_APP_COOKIE_ENV === 'staging') ? '.tradier.com' : 'localhost',
  sameSite: 'strict',
  secure: true
};

export function getTokenFromCookie() {
  return Cookies.get(TOKEN_KEY);
}

export function saveTokenToCookie(token, expiration) {
  // console.log('saveTokenToCookie', token, expiration);
  if(!token)
    return;

  Cookies.set(TOKEN_KEY, token, COOKIE_OPTIONS);
  Cookies.set(TOKEN_EXPIRATION, expiration, COOKIE_OPTIONS);
}

export function clearTokenCookie() {
  // console.log('clearTokenCookie');
  Cookies.remove(TOKEN_KEY, COOKIE_OPTIONS);
  Cookies.remove(TOKEN_EXPIRATION, COOKIE_OPTIONS);
}

export function getOnboardingParamsCookie() {
  return Cookies.get(ONBOARDING_PARAMS_TOKEN_KEY);
}

export const setOnboardingParamsCookies = () => {
  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);

  let cookieValue = {};

  // if the cookie already exists, use that as the base value
  if (Cookies.get(ONBOARDING_PARAMS_TOKEN_KEY)) cookieValue = JSON.parse(Cookies.get(ONBOARDING_PARAMS_TOKEN_KEY));

  // if we ever see a promo and platformId, set a session cookie
  // we'll use this to prefill the application in onboarding
  if (params.has('promo')) cookieValue.promotionCode = params.get('promo');
  if (params.has('platform')) cookieValue.platformId = params.get('platform');

  if (Cookies.get('_gcl_aw')) {
    try {
      const gclid = Cookies.get('_gcl_aw');
      cookieValue.gclid = gclid.split('.')[2];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  // check for a mixpanel cookie and push values to our own cookie
  if (Cookies.get(`mp_${process.env.VUE_APP_MIXPANEL}_mixpanel`)) {
    try {
      const mixpanelParams = JSON.parse(Cookies.get(`mp_${process.env.VUE_APP_MIXPANEL}_mixpanel`));
      Object.assign(cookieValue, pick(mixpanelParams, ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term']));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  // check for CJ event and push value to our own cookie (cje)
  if (Cookies.get('cje')) cookieValue.cje = Cookies.get('cje');

  // set our cookie
  Cookies.set(ONBOARDING_PARAMS_TOKEN_KEY, JSON.stringify(cookieValue), ONBOARDING_PARAMS_COOKIE_OPTIONS);

  // remove params we want to hide
  params.delete('promo');
  params.delete('platform');

  const qs = params.toString();
  if (qs !== '') {
    url.search = `?${qs}`;
  } else {
    url.search = '';
  }

  // clear the URL of params
  window.history.replaceState({}, document.title, url.toString());
};

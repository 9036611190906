export const hashedEmail = async (email) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);

  const buffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(buffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

export const pick = (object, keys) => {
  if (!object || typeof object !== 'object') {
    throw new Error('First argument must be an object');
  }

  if (!Array.isArray(keys)) {
    throw new Error('Second argument must be an array of keys');
  }

  const result = {};
  keys.forEach(key => {
    if (key in object) {
      result[key] = object[key];
    }
  });

  return result;
};
